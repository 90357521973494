// Import our CSS
import "@/scss/app.scss";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import AOS from "aos";
import Alpine from "alpinejs";
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse'
import { Tooltip } from 'flowbite';

// Internal
import { menu } from "./components/menu.js";
import { scrollLink } from "./components/scrollLink.js";
import { lazyLoadVideo } from "./components/lazyLoadVideo.js";

window.addEventListener("load", (event) => {
  AOS.init({
    once: "true",
  });
});

// JS callback functions for:
// 1. Hamburger Menu toggle.
// 2. Show / Hide Mobile Menu (on hamburger button click)
menu();
scrollLink();

window.Tooltip = Tooltip;
window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(collapse);

window.addEventListener('load', function(event) {
    Alpine.start();
});

// Get all elements with the class "child"
const childElements = document.querySelectorAll(".fire-form");

// Loop through each child element
childElements.forEach((childElement) => {
  // Add a class to the parent of each child
  const parentElement = childElement.parentNode;
  if (parentElement) {
    parentElement.classList.add("gslide-media-custom");
  }
});

document.addEventListener("DOMContentLoaded", function() {
    lazyLoadVideo();
});

window.getCookie = function(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

let qtyDigitUpdater = document.querySelectorAll('.js-cart-qty');
let qty = getCookie('csqty');
qtyDigitUpdater.forEach(function(item) {
    item.innerHTML = qty;
    if(qty != '' && qty != 0) {
        item.classList.remove("hidden");
    } else {
        item.classList.add("hidden");
    }
});

document.addEventListener('DOMContentLoaded', function () {
    Alpine.store('entry', {
        galleryImages: [],
        bannerImage: [],
        push(id, url, field)
        {
            switch(field) {
            case "galleryImages":
                this.galleryImages.push(...[{
                    id: id,
                    url: url
                }]);
                break;
            case "bannerImage":
                this.bannerImage.push(...[{
                    id: id,
                    url: url
                }]);
                break;
            default:
                this.galleryImages.push(...[{
                    id: id,
                    url: url
                }]);
            }

        }
    });

    Alpine.data("alpineMuliSelect", (obj) => ({
        elementId: obj.elementId,
        options: [],
        selected: Array.isArray(obj.selected) ? obj.selected : [obj.selected],
        limit: obj.limit ?? null, // Set your desired limit here
        selectedElms: [],
        show: false,
        search: '',
        focusedOptionIndex: null,
        open() {
            this.generateOptions();

            this.show = true;
            this.focusedOptionIndex = null;

            if(this.limit == 1) {
                for (var i = 0; i < this.options.length; i++) {
                    if(this.options[i].selected) {
                        this.focusedOptionIndex = i;
                    }
                }
            }
        },
        close() {
            this.show = false;
            this.focusedOptionIndex = null;
            this.search = '';
        },
        toggle() {
            this.show = !this.show;
        },
        isOpen() {
            return this.show === true;
        },

        // Initializing component
        init() {
            this.createSelectedElements();

            // searching for the given value
            this.$watch("search", (e => {
                this.focusedOptionIndex = null;
                this.generateOptions();
            }));
        },
        // clear search field
        clear() {
            this.search = '';
        },
        // deselect selected options
        deselect() {
            setTimeout(() => {
                this.selected = [];
                this.selectedElms = [];
                Object.keys(this.options).forEach((key) => {
                    this.options[key].selected = false;
                });
            }, 100);
        },
        // select given option
        select(index, target) {
            if (this.limit === 1) {
                if (!this.options[index].selected) {
                    this.options.forEach(opt => {
                        opt.selected = false;
                    });
                    this.selected = [];
                    this.selectedElms = [];

                    // Override selection
                    this.options[index].selected = true;
                    this.options[index].element = target;

                    this.selected.push(this.options[index].value);
                    this.selectedElms = [this.options[index]];

                    this.close();
                }
            }

            else {
                if (! this.options[index].selected) {
                    if(this.limit == null || this.selected.length <= this.limit) {
                        this.options[index].selected = true;
                        this.options[index].element = target;
                        this.selected.push(this.options[index].value);
                        this.selectedElms.push(this.options[index]);

                        if (this.limit == this.selectedElms.length) {
                            this.close();
                        }
                    }
                } else {
                    this.selected.splice(this.selected.lastIndexOf(index), 1);
                    this.options[index].selected = false;
                    Object.keys(this.selectedElms).forEach((key) => {
                        if (this.selectedElms[key].value == this.options[index].value) {
                            setTimeout(() => {
                                this.selectedElms.splice(key, 1);
                            }, 10);
                        }
                    })
                }
            }

        },

        // remove from selected option
        remove(index, option) {
            this.selectedElms.splice(index, 1);
            Object.keys(this.options).forEach((key) => {
                if (this.options[key].value == option.value) {
                    this.options[key].selected = false;
                    Object.keys(this.selected).forEach((skey) => {
                        if (this.selected[skey] == option.value) {
                            this.selected.splice(skey, 1);
                        }
                    });
                }
            });
        },

        selectOption() {
            if (this.isOpen() && this.focusedOptionIndex !== null) {
                /*let focusedOption = this.options[this.focusedOptionIndex];
                console.log(focusedOption);

                focusedOption.selected = !focusedOption.selected;*/

                let optionElement = document.getElementById(this.elementId).parentElement.querySelector(`li[data-key='${this.focusedOptionIndex}']`);
                this.select(this.focusedOptionIndex, optionElement);
            }
        },

        focusPreviousOption() {
            if (this.isOpen()) {
                if (this.focusedOptionIndex === null) {
                    this.focusedOptionIndex = this.options.length - 1;
                } else if (this.focusedOptionIndex > 0) {
                    this.options[this.focusedOptionIndex].focused = false;
                    this.focusedOptionIndex--;
                }

                this.options[this.focusedOptionIndex].focused = true;

                this.$nextTick(() => {
                    let optionElement = document.getElementById(this.elementId).parentElement.querySelector(`li[data-key='${this.focusedOptionIndex}']`);
                    if (optionElement) {
                        optionElement.scrollIntoView({
                            block: 'center',
                        });
                    }
                });
            }
        },

        focusNextOption() {
            if (this.isOpen()) {
                if (this.focusedOptionIndex === null) {
                    this.focusedOptionIndex = 0;
                } else if (this.focusedOptionIndex < this.options.length - 1) {
                    this.options[this.focusedOptionIndex].focused = false;
                    this.focusedOptionIndex++;
                }

                this.options[this.focusedOptionIndex].focused = true;

                this.$nextTick(() => {
                    let optionElement = document.getElementById(this.elementId).parentElement.querySelector(`li[data-key='${this.focusedOptionIndex}']`);
                    if (optionElement) {
                        optionElement.scrollIntoView({
                            block: 'center',
                        });
                    }
                });
            }
        },

        // filter out selected elements
        selectedElements() {
            return this.options.filter(op => op.selected === true);
        },

        generateOptions() {
            this.options = [];
            let options = document.getElementById(this.elementId).options;
            let reg = new RegExp(this.search, 'gi');

            for (let i = 0; i < options.length; i++) {

                let option = {
                    value: options[i].value,
                    text: options[i].innerText,
                    search: options[i].dataset.search,
                    selected: Object.values(this.selected).includes(options[i].value)
                };

                if(this.search == null || (this.search != null && options[i].dataset.search.match(reg))) {
                    this.options.push(option);
                }
            }
        },

        createSelectedElements() {
            this.options = [];
            this.selectedElms = [];
            let options = document.getElementById(this.elementId).options;
            let reg = new RegExp(this.search, 'gi');

            for (let i = 0; i < options.length; i++) {
                if(Object.values(this.selected).includes(options[i].value)) {
                    let option = {
                        value: options[i].value,
                        text: options[i].innerText,
                        search: options[i].dataset.search,
                        selected: true
                    };

                    this.selectedElms.push(option);
                }
            }
        },
    }));
});
