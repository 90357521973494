export function scrollLink(wrapper = "scroll-link") {
    let smoothScrollLinks = document.querySelectorAll("." + wrapper);

    smoothScrollLinks.forEach(link => {
        let targetId = link.getAttribute('href').substring(1);
        let targetSection = document.getElementById(targetId);
        let headerOffset = parseInt(link.getAttribute('data-header-offset')) || 112;

        window.addEventListener('scroll', function (e) {
            let scrollPosition = window.scrollY;
            if (targetSection) {
                if (
                    scrollPosition >= targetSection.offsetTop - headerOffset &&
                    scrollPosition < targetSection.offsetTop + targetSection.offsetHeight - headerOffset
                ) {
                    link.classList.add('!text-orange-500');
                } else {
                    link.classList.remove('!text-orange-500');
                }
            }
        });

        link.addEventListener('click', function(e) {
            e.preventDefault();
            window.scroll({
                behavior: 'smooth',
                top: targetSection.offsetTop - headerOffset,
            });
            // console.log(targetId + '|' + targetSection.offsetTop + '|' + headerOffset);
        });
    });
}
